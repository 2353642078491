<template>
  <div class="bg-gray-25 h-full">
    <div v-if="currentStep < 3" @click="close" class="w-full flex items-center justify-end space-x-3 pt-10 pr-10 cursor-pointer">
      <div class="uppercase body-l-500">Close</div>
      <CloseIcon />
    </div>
    <div class="grid grid-cols-1">
      <div class="w-full max-w-lg mx-auto py-20 px-6 xl:px-0">
        <transition
          enter-active-class="transition ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          mode="out-in"
        >
          <router-view></router-view>
        </transition>
      </div>
    </div>
    <CancelMiniviewModal ref="cancelMiniviewModal" />
  </div>
</template>

<script>
import CancelMiniviewModal from '@/components/miniviews/CancelMiniviewModal';
import CloseIcon from '@/components/svgs/CloseIcon';
import { mapMutations, mapState } from 'vuex';

export default {
  name: "CreateMiniview",

  components: { CancelMiniviewModal, CloseIcon },

  computed: {
    ...mapState({
        currentStep: state => state.miniviews.currentStep,
      }),
  },

  methods: {
    ...mapMutations({
      setTitle: "miniviews/setTitle",
      setCurrentStep: "miniviews/setCurrentStep",
    }),

    close() {
      this.$refs.cancelMiniviewModal.toggle();
    }
  },

  beforeDestroy() {
    this.setTitle('');
    this.setCurrentStep(1);
  },
};
</script>
<style scoped>
.body-l-500{
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
</style>
