<template>
<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.41425 7.50025L13.7072 2.20725C14.0982 1.81625 14.0982 1.18425 13.7072 0.79325C13.3162 0.40225 12.6842 0.40225 12.2933 0.79325L7.00025 6.08625L1.70725 0.79325C1.31625 0.40225 0.68425 0.40225 0.29325 0.79325C-0.09775 1.18425 -0.09775 1.81625 0.29325 2.20725L5.58625 7.50025L0.29325 12.7933C-0.09775 13.1842 -0.09775 13.8162 0.29325 14.2072C0.48825 14.4022 0.74425 14.5002 1.00025 14.5002C1.25625 14.5002 1.51225 14.4022 1.70725 14.2072L7.00025 8.91425L12.2933 14.2072C12.4882 14.4022 12.7443 14.5002 13.0002 14.5002C13.2562 14.5002 13.5122 14.4022 13.7072 14.2072C14.0982 13.8162 14.0982 13.1842 13.7072 12.7933L8.41425 7.50025Z" fill="#292546"/>
</svg>
</template>

<script>
    export default {
        name: 'CloseIcon'
    }
</script>

<style lang="scss" scoped>

</style>