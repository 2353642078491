<template>
  <modal
    :with-close-button="false"
    ref="modal"
  >
    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <div class="flex justify-between items-center">
            <div class="flex items-center space-x-2">
              <span class="rounded-full h-10 w-10 bg-pink-500 bg-opacity-10 flex items-center justify-center">
                <WarningIcon class="h-5 w-5 text-pink-500" />
              </span>
              <h3 class="font-bold font-poppins text-blue-800">
                Are you sure you want to leave?
              </h3>
            </div>
            <div>
              <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                <XIcon class="w-6 h-6" />
              </button>
            </div>
          </div>

          <div class="text-sm text-blue-800 font-medium mt-6">
            Your MINIVIEW™ process and all the information entered will be lost.
          </div>

          <div class="flex items-center justify-between mt-6">
            <button @click="close" type="button" class="text-pink-500 font-bold text-sm">
              Cancel
            </button>

            <button @click="leave(close)" type="button" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 h-11 flex items-center justify-center disabled:opacity-60 relative">
              <span>
                Yes, leave
              </span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import XIcon from '@/components/svgs/XIcon'
import WarningIcon from '@/components/svgs/WarningIcon'

export default {
  name: 'CancelMiniviewModal',
  components: {
    Modal,
    WarningIcon,
    XIcon,
  },

  methods: {
    toggle() {
      this.$refs.modal.toggle();
    },

    leave(callback) {
      this.$router.push({ name: 'Miniviews' });
      callback.call();
    },
  },
}
</script>
